.form-button-wrap {
    position: relative;
    width: 100%;
}

.form-button-wrap.loading button,
.form-button-wrap.success button {
    background-color: hsl(var(--bg-accent));
    user-select: none !important;
    pointer-events: none;
    color: transparent;
}

.form-button-wrap img {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    height: 30px;
    width: 30px;

    animation: rotate-infinite 0.7s linear infinite;
}   