#profile {
    --header-height: 30px;
    --footer-height: 40px;
    --animation-delay: 1.25s;

    position: relative;
    padding-top: var(--profile-nav-height);

    width: 100%;
    height: 100%;
    
    display: none;
}

#profile .header {
    position: relative;
    
    width: 100%;
    height: 30px;

    padding-inline: var(--profile-padding);
    display: flex;
    align-items: center;
}

#profile .header h1 {
    margin-left: .75rem;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.10em;
    color: hsl(var(--fs-accent));

    margin-bottom: 0.25rem;

    opacity: 0;
    animation: opacity-1 0.5s ease .8s forwards;
}

#profile h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    line-height: 1em;
    color: hsl(var(--fs-accent));

    opacity: 0;
    animation: opacity-1 0.5s ease 1.1s forwards;
}

#profile .sub {
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 0.025em;
}

#profile main {
    width: calc(100% - var(--profile-padding) * 2);
    height: calc(100% - var(--header-height) - var(--footer-height));
    margin-inline: auto;

    overflow-y: auto;
    transition-duration: 1s;
}
#profile main::-webkit-scrollbar {
    display: none;
}

#profile main .row-1 {
    position: relative;

    width: 100%;
    min-height: 300px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
}

#profile main .row-1 > * + * {
    border-left: 3px solid hsl(var(--bg-fff) / .05);
}

/* profile picture */
#profile main .picture {
    position: relative;

    width: 100%;
    height: 100%;
    max-height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}

#profile main .picture > a {
    width: calc(100% - 1rem - 3px);
    height: calc(100% - 1rem - 3px);
    margin-bottom: 3px;
    background-position: center;
    background-size: cover;
}

#profile main .picture #no-picture {
    width: calc(100% - 1rem - 3px);
    height: calc(100% - 1rem - 3px);
    margin-bottom: 3px;
    opacity: 0.2;
}

#profile main .picture #no-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#profile main .picture a {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    color: hsl(var(--fs-accent));
}

#profile main .no-picture {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* profile infos */
#profile main a.infos {   
    position: relative;

    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.2s forwards;
}

#profile main .infos-header {
    margin-bottom: 1rem;
} 

#profile main .infos-header .sub {
    font-size: 14px;
}


/*  row 2  */
#profile main .row-2 {
    position: relative;

    width: 100%;
    height: 50px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

#profile main .row-2 button {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.15em;
    
    width: 100%;
    height: 100%;
    background-color: transparent;

    opacity: 0;
    animation: opacity-1 0.35s ease 1.2s forwards;
}
#profile main .row-2 button + button {
    animation-delay: 1.4s;
}
#profile main .row-2 button:hover {
    background-color: hsl(var(--bg-fff) / .01);
}
#profile main .row-2 button.active {
    background-color: hsl(var(--bg-fff) / .01);
    color: hsl(var(--fs-accent));
}





/* footer */
#profile .logout {
    position: relative;
    z-index: 1;

    width: 100%;
    height: var(--footer-height);

    display: flex;
    align-items: center;
    justify-content: center;
}

#profile .logout button {
    display: flex;
    align-items: center;
    color: hsl(var(--fs-fff) / .6);

    opacity: 0;
    animation: opacity-1 0.5s ease 1.1s forwards;
}

#profile .logout button img {
    margin-left: .5em;
    opacity: 0.6;
}

#profile #footer-line {
    top: 0;
    left: 0;

    clip-path: inset(0 100% 0 0);
}



@media screen and (max-width: 1100px) {
    :root {
        --profile-padding: 1.5rem;
    }

    #profile main .row-1 {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
    }

    #profile main .row-1 .picture {
        aspect-ratio: 5 / 3;
    }

    #profile main .row-1 > * + * {
        border-left: none;
        border-top: 3px solid hsl(var(--bg-fff) / .05);
    }
}





/*  misc  */
#profile #header-line {
    bottom: 0;

    clip-path: inset(0 0 0 100%);
}

#profile #left-line {
    top: 0;
    left: calc(var(--profile-padding) - 3px);

    clip-path: inset(100% 0 0 0);
}

#profile #right-line {
    top: 0;
    right: calc(var(--profile-padding) - 3px);

    clip-path: inset(100% 0 0 0);
}

#profile #row-1-line {
    bottom: 0;

    clip-path: inset(0 0 0 100%);
}

#profile #row-2-line {
    bottom: 0;

    clip-path: inset(0 100% 0 0);
}

#profile #row-2-v-line {
    left: 50%;
    translate: -50%;

    clip-path: inset(0 0 100% 0);
    animation-delay: 0.75s;
}



/*  profile animation out  */
#root:has(#profile.out) #profile-nav {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#profile.out main {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#profile.out .header h1 {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#profile.out .logout button {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#profile.out #header-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-r 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#profile.out #footer-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-l 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#profile.out #left-line,
#profile.out #right-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-b 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#profile.out #row-1-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-r 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#profile.out #row-2-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-l 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}