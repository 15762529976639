.input-wrap {
    --calendar-input-image: '';

    position: relative;
}

.input-wrap input {
    width: 100%;
    height: var(--input-height);
    font-family: 'Poppins';
    font-size: var(--input-fs);
    font-weight: 500;
    border: 3px solid hsl(var(--bg-fff) / .1);
    border-radius: 0;
    background-color: hsl(var(--bg-fff) / 0);
    color: var(--fs-fff);
    padding-inline: 1em;

    transition: 0.3s ease;
}
.input-wrap input:focus {
    outline: none;
    background-color: hsl(var(--bg-fff) / .05);
    border-color: hsl(var(--bg-fff) / 0.3);
}

.input-wrap label {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);

    font-weight: 500;
    color: hsl(var(--bg-fff) / 0.8);

    transition: .3s ease;

    pointer-events: none;
    user-select: none;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

.input-wrap.done label {
    top: 0;
    background-color: hsl(var(--bg-111));
    padding-inline: 0.5em;
    font-size: 12px;
    opacity: 0.6;
}

.input-wrap img {
    position: absolute;
    right: 1rem;
    top: 50%;
    translate: 0 -50%;
    
    width: 15px;
    height: 15px;

    visibility: hidden;
    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

.input-wrap .visible img:first-of-type {
    visibility: visible;
}

.input-wrap .invisible img:last-of-type {
    visibility: visible;
}

input {
    clip-path: inset(100% 0 0 0);
    animation: clip-path-0 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 1s forwards;
}


.out input {
    clip-path: inset(0 0 0 0);
    animation: clip-path-t 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0s forwards;
}

.out .input-wrap label,
.out .input-wrap img {
    opacity: 1;
    animation: opacity-0 0.5s ease 0s forwards;
}


/* mod input */
.input-wrap[data-status]:has(input:focus) label::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc(50% + 3px);
    left: 0;

    width: 100%;
    height: calc(50% - 3px);
    background-color: hsl(var(--bg-fff) / .05);
}

.input-wrap[data-status] input {
    padding-right: 60px;
}

.input-wrap .mod-button {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 1rem;
    translate: 0 -50%;

    width: min-content;
    white-space: nowrap;
    height: 70%;

    transition: 0.3s ease;
}

.input-wrap[data-status="empty"] .mod-button,
.input-wrap[data-status="filled"] .mod-button {
    color: hsl(var(--fs-accent));
}

.input-wrap[data-status="editing"] .mod-button {
    color: hsl(var(--fs-accent));
}

.input-wrap[data-status="saving"] .mod-button img {
    visibility: visible;
    
    position: absolute;
    z-index: 5000;
    top: 50%;
    right: .15rem;
    translate: -8px -50%;

    opacity: 1;
    animation: rotate-infinite 0.7s linear infinite;
}


/* height input */
.height-input {
    height: 3em;
    border: 3px solid hsl(var(--bg-fff) / .1);
    
    display: flex;
    align-items: center;
}
.height-input:has(input:focus) {
    background-color: hsl(var(--bg-fff) / .05);
    border: 3px solid hsl(var(--bg-fff) / .3);
}

.height-input input {
    width: 30px;
    padding: 10px;
    border: none;
}
.height-input input:first-of-type {
    margin-left: .4rem;
}
.height-input input:last-of-type {
    width: 35px;
    text-align: center;
}
.height-input input:focus {
    background-color: unset;
}

.height-input p {
    font-size: 14px;
    color: hsl(var(--bg-fff) / .5);
}
.height-input button {
    color: hsl(var(--fs-accent));
}


/* datepicker icon */
.input-wrap input::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: var(--calendar-input-image) no-repeat;
    filter: invert();
    width: 20px;
    height: 20px;
    border-width: 0;
}
