/* no team */
#profile .no-team {
    background-color: hsl(var(--bg-fff) / .01);
    width: 100%;
    text-align: center;

    padding: 4rem;
}
#profile .no-team p {
    margin-bottom: 2rem;
}



/* has team */
#profile .teams {
    position: relative;

    width: 100%;
    background-color: hsl(var(--bg-fff) / .01);

    padding: 2rem;
}

#profile .teams .controls {
    width: 100%;
    height: auto;
    padding-inline: .75rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

#profile .teams .controls > div {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

#profile .teams .controls button {
    color: hsl(var(--bg-fff) / .6);
    transition: 0.3s ease;
}
#profile .teams .controls button:hover {
    color: hsl(var(--bg-accent));
}

#profile .teams .controls button svg {
    height: 20px;
    width: auto;
    display: flex;
    align-items: center;
}

#profile .teams .controls button:hover svg path {
    fill: hsl(var(--bg-accent));
}

#profile .team {
    width: 100%;

    opacity: 0;
    animation: opacity-1 0.35s ease var(--animation-delay) forwards;
}

#profile .team h2 {
    margin-bottom: 2rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

#profile .team ul#banners {
    --nb-banners: 5;

    position: relative;

    width: 100%;
    height: auto;
    
    display: grid;
    grid-template-columns: repeat(var(--nb-banners), 250px);
    column-gap: 3rem;

    padding-bottom: 2rem;
    padding-inline: 1rem;
    overflow-x: auto;
}
#profile .team ul#banners::-webkit-scrollbar {
    height: 10px;
    background-color: hsl(var(--bg-fff) / .1);
}
#profile .team ul#banners::-webkit-scrollbar-thumb {
    background-color: hsl(var(--bg-fff) / .2);
}   

#profile .team > p.hold-shift {
    display: none;
}


@media screen and (max-width: 1100px) {
    #profile .teams .controls {
        display: block;
        align-items: unset;

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                display: flex;
                column-gap: .75rem;
            }
        }

        > button {
            margin-top: .5rem;
        }
    }
}

@media (hover: hover) and (pointer: fine) {
    #profile .team ul#banners {
        padding-bottom: 3rem;
    }

    #profile .team > p.hold-shift {
        display: unset;
        position: absolute;
        left: 50%;
        bottom: .5rem;
        translate: -50%;

        font-size: 10px;
    }
}