/* reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: hsl(var(--bg-111));
  color: hsl(var(--bg-fff));
  font-family: 'Poppins';
  font-size: 1rem;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
    background-color: hsl(var(--bg-111));
  overflow-x: hidden;
  overflow-y: auto;
}

button {
  outline: none;
  border: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-transform: inherit;
  cursor: pointer;
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
  margin: 0;
}
