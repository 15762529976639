#lang-modal .modal {
    width: min(600px, 95%);
}

#lang-modal .modal {
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content 200px;
    column-gap: .75rem;
    row-gap: 2rem;
    padding-bottom: 3rem;
}

#lang-modal .modal h2 {
    grid-column: span 2;
    margin-bottom: 0;

    font-weight: 500;
    color: hsl(var(--fs-accent));
}

#lang-modal .modal .card-01 {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 22px;
    font-weight: 400;
    letter-spacing: .1em;
    color: hsl(var(--fs-fff) / .8);

    cursor: pointer;
    transition: 0.3s ease;
}
#lang-modal .modal .card-01:hover {
    border: 3px solid hsl(var(--bg-accent));
    color: hsl(var(--fs-accent));
}


@media screen and (max-width: 400px) {
    #lang-modal .modal {
        grid-template-columns: 1fr;
        grid-template-rows: max-content 100px 100px;
        column-gap: unset;
        row-gap: 2rem;
        padding-bottom: 3rem;
    }
    
    #lang-modal .modal h2 {
        grid-column: unset;
    }
}