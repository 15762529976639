.sports-modal-wrap {
    position: fixed;
    z-index: 3000;    
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-000) / 0.5 );

    opacity: 0;
    animation: opacity-1 0.3s ease forwards;
}

.sports-modal {
    position: relative; 

    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-111));

    clip-path: inset(100% 0 0 0);
    animation: clip-path-0 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) .5s forwards;
}

.sports-modal .close {
    position: absolute;
    top: 2rem;
    left: 50%;
    translate: -50%;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.sports-modal .close p,
.sports-modal .controls {
    font-size: 14px;
    letter-spacing: 0.1em;
    color: hsl(var(--fs-accent));
    width: max-content;
}

.sports-modal .close:hover p,
.sports-modal .controls button:hover {
    color: hsl(var(--fs-accent) / .7);
}

.sports-modal .close img {
    margin-left: .3rem;

    width: 12px;
    height: 12px;
    object-fit: contain;
}

.sports-modal .controls {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    translate: -50%;

    display: flex;
    gap: 2rem; 
}



/*       input roulette        */
.input-roulette {
    --step: 0;

    position: absolute;
    top: calc(50% - 50px);
    left: 50%;
    translate: -50%;

    width: 350px;
    height: calc(50% + 50px);

    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.input-roulette.down {
    translate: -50% calc(100% - 10rem);
}

.input-roulette .part {
    position: absolute;
    
    width: 100%;
    height: 100%;

    transform-origin: bottom center;
    opacity: 0.3;
    transition: 0.8s cubic-bezier(0.645, 0.045, 0.355, 1),
}

.input-roulette .part.active {
    opacity: 1;
}

.input-roulette .part.hidden {
    visibility: hidden;
}

.input-roulette .part:first-child {
    rotate: calc(var(--step) * -90deg);
}

.input-roulette .part:nth-child(2) {
    rotate: calc(var(--step) * -90deg + 90deg);
}

.input-roulette .part:nth-child(3) {
    rotate: calc(var(--step) * -90deg + 180deg);
}

.input-roulette .part:nth-child(4) {
    rotate: calc(var(--step) * -90deg + 270deg);
}

.input-roulette .part:nth-child(5) {
    rotate: calc(var(--step) * -90deg);
}

.input-roulette .part:has(.border-button + .border-button) {
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.input-roulette .part:has(.border-button) .border-button {
    max-height: 4em;
    min-width: 150px;
}