.input-row {
    width: 100%;
    margin-bottom: 1rem;
    display: grid;
    grid-gap: var(--grid-gap);
}

.input-row.one {
    grid-template-columns: 100%;
}

.input-row.two {
    grid-template-columns: repeat(2, 1fr);
}

.input-row.three {
    grid-template-columns: repeat(3, 1fr);
}

.input-row.four {
    grid-template-columns: repeat(4, 1fr);
}

.input-row.five {
    grid-template-columns: repeat(4, 1fr);
}


.input-row.six,
.input-row.seven {
    grid-template-columns: repeat(7, 1fr); 
}


@media screen and (max-width: 749px) {
    .input-row.one,
    .input-row.two,
    .input-row.three,
    .input-row.four {
        grid-template-columns: 100%;
    }

    .input-row.six,
    .input-row.seven {
        grid-template-columns: repeat(3, 1fr);
    }
}