.dd {
    position: relative;

    width: 100%;
    height: var(--input-height);
}

.dd input {
    width: 100%;
    height: var(--input-height);
    border: 3px solid hsl(var(--bg-fff) / .1);
    border-radius: 0;
    background-color: hsl(var(--bg-fff) / 0);
    color: var(--fs-fff);

    font-family: 'Poppins';
    font-size: var(--input-fs);
    font-weight: 500;

    padding-inline: 1em;

    transition: color 0.3s ease;
}
.dd.open.down input {
    border-bottom: none;
}
.dd.open.up input {
    border-top: none;
}
.dd input:focus,
.dd.open input {
    outline: none;
    background-color: #1D1D1D;
    border-color: hsl(var(--bg-fff) / 0.3);
}

.dd label {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);

    font-weight: 500;
    color: hsl(var(--bg-fff) / 0.8);

    transition: .3s ease;

    pointer-events: none;
    user-select: none;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}
.dd.up.open label {
    clip-path: inset(100% 0 0 0);
}
.dd.done label {
    top: 0;
    background-color: hsl(var(--bg-111));
    padding-inline: 0.5em;
    font-size: 12px;
    opacity: 0.6;
}

.dd .dd-header {
    position: relative;

    width: 100%;
    height: 100%;
}

.dd .dd-header img {
    position: absolute;
    top: 50%;
    right: 1rem;
    translate: 0 -50%;
    rotate: 0deg;

    filter: saturate(0);
    height: 15px;
    width: 15px;
    object-fit: contain;
    user-select: none;

    transition: 0.3s ease;
    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}
.dd.open .dd-header img {
    rotate: 90deg;
}

.dd .dd-body {
    display: none;
    position: absolute;
    z-index: 1;
    left: 0;

    width: 100%;
    max-height: 190px;
    background-color: #1D1D1D;
    border: 3px solid hsl(var(--bg-fff) / 0.3);
    
    overflow: auto;
}
.dd.down .dd-body {
    top: calc(100% - 1px);
    border-top: none;
}
.dd.up .dd-body {
    bottom: calc(100% - 1px);
    border-bottom: none;
}

.dd.open .dd-body {
    display: block;
}

.dd .dd-body li button {
    padding-inline: 1rem;

    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 2.5em;
    cursor: pointer;
}
.dd .dd-body li:hover {
    background-color: #262626;
}

.out .dd-header label,
.out .dd-header img {
    opacity: 1;
    animation: opacity-0 0.5s ease 0s forwards;
}



.dd .dd-body {

}