#test {
    display: none;
    width: 100%;
    height: 100%;
}

#test .part {
    padding-top: 2rem;
    padding-bottom: 6rem;
    margin-inline: auto;

    width: var(--large-container-width) !important;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    gap: 2rem;
}

#test .part main {
    grid-row: 2;
    grid-column: span 2;
}

/*  part presentation pages  */
#test .part-page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 3rem;
}

#test .part-page h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: .5em;
    text-align: center;
    text-transform: uppercase;

    animation: slide-up-3d 1s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
#test .part-page h1 span {
    color: hsl(var(--fs-accent));
    letter-spacing: 0 !important;
}

#test .part-page p {
    max-width: min(550px, 85%);
    text-align: center;

    opacity: 0;
    animation: slide-up-3d 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
}

#test .part-page button {
    opacity: 0;
    animation: slide-up-3d 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s forwards;
}

#test .part-page.out h1 {
    animation: slide-up-3d-out .5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
#test .part-page.out p {
    opacity: 1;
    animation: slide-up-3d-out .5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
}
#test .part-page.out button {
    opacity: 1;
    animation: slide-up-3d-out .5s ease .4s forwards;
}


/*  progress bar  */
#test .progress-bar {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 251px;
    height: 6rem;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    animation: slide-up-3d 1s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
#test:has(.last-out) .progress-bar {
    animation: opacity-0 .5s ease forwards;
}

#test .progress-bar .square {
    width: 2rem;
    height: 2rem;
    background-color: hsl(var(--bg-fff) / .1);
    font-size: 12px;
    text-shadow: 1px 1px 2px #151515;

    display: grid;
    place-items: center;

    user-select: none;
    pointer-events: none;
    transition: 0.3s ease;
}
#test .progress-bar .square.orange {
    background-color: hsl(var(--bg-accent));
}

#test .progress-bar .bar {
    --right: 100%;
    position: relative;

    width: 41px;
    height: 5px;
    background-color: hsl(var(--bg-fff) / .1);
}
#test .progress-bar .bar::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-accent));

    clip-path: inset(0 calc(var(--right) * 1px) 0 0);
    transition: 0.3s ease;
}


/*  blocks header  */
#test header {
    position: fixed;
    top: 2rem;
    left: 0;

    width: 100%;

    padding-inline: calc((100% - var(--large-container-width)) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#test .first-in header {
    opacity: 0;
    animation: opacity-1 0.5s ease forwards;
}
#test .last-out header {
    animation: opacity-0 0.3s ease forwards;
}

#test h2 {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: hsl(var(--fs-accent));

    display: flex;
    align-items: center;
}

#test h3 {
    font-size: 18px;
    font-weight: 400;
}



/* ------------------------ */
/* ------- intro ---------- */
/* ------------------------ */
#test .intro {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

#test .intro button {
    position: relative;
    z-index: 1;

    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: hsl(var(--fs-accent));
    cursor: pointer;

    opacity: 1;
    scale: 1;
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#test .intro svg {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    rotate: 0deg;
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* on enter */
#test .intro button {
    animation: slide-up-3d .8s cubic-bezier(0.215, 0.610, 0.355, 1);
}
#test .intro svg:first-of-type {
    animation: rotate-in-left .8s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#test .intro svg:last-of-type {
    animation: rotate-in-right .8s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#test .intro a {
    opacity: 0;
    animation: slide-up-3d-px 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
}

/* on hover */
#test .intro button:hover {
    scale: 1.1;
}
#test .intro:has(button:hover) svg:first-of-type {
    rotate: -10deg;
}
#test .intro:has(button:hover) svg:last-of-type {
    rotate: 10deg;
}

/* on exit */
#test .intro.out button {
    scale: 0.3;
    opacity: 0;
    transition: 0.5s;
}
#test .intro.out svg:first-of-type {
    scale: 0.8 !important;
    opacity: 0 !important;
    rotate: -60deg !important;
}
#test .intro.out svg:last-of-type {
    scale: 0.8 !important;
    opacity: 0 !important;
    rotate: 60deg !important;
}



/* ------------------------ */
/* ------- part 1 --------- */
/* ------------------------ */
#test .part.one {
    width: 100%;
    height: 100%;
}

#test .part.one main {
    opacity: 0;
    animation: slide-up-3d-px 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
}
#test .part.one.out main {
    opacity: 1;
    animation: slide-up-3d-px-out .5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
}

#test .part.one main ul {
    position: relative;
    
    width: 100%;
    height: 100%;
    
    display: grid;
    grid-template-rows: repeat(7, 1fr);
}

#test .part.one main ul li {
    position: relative;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10rem;
    align-items: center;
}

#test .part.one main ul .line {
    left: 50%;
    clip-path: inset(100% 0 0 0);
    animation-delay: .8s;
}





/* ------------------------ */
/* ------- part 2 --------- */
/* ------------------------ */
#test .part.two.animates h2 {
    animation: slide-up-3d-px .7s cubic-bezier(0.645, 0.045, 0.355, 1) 0s forwards;
}
#test .part.two.animates.out h2 {
    animation: slide-up-3d-px-out .7s cubic-bezier(0.645, 0.045, 0.355, 1) 0s forwards;
}

#test .part.two main {
    opacity: 0;
    animation: slide-up-3d-px 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s forwards;
}
#test .part.two.out main {
    opacity: 1;
    animation: slide-up-3d-px-out .5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
}

#test .part.two .inner {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
    place-items: center;
}

#test .part.two main button {
    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-fff) / .02);
    border: 3px solid hsl(var(--bg-fff) / .08);
    padding: 1.5rem;

    transition: 0.3s ease;
}
#test .part.two main button:hover {
    background-color: hsl(var(--bg-fff) / .05);
    border-color: hsl(var(--bg-fff) / .1);
}
#test .part.two main button.selected {
    background-color: hsl(var(--bg-fff) / .06);
    border-color: hsl(var(--fs-accent));
}




/* ------------------------ */
/* ------- part 3 --------- */
/* ------------------------ */
#test .part.three h2 {
    opacity: 0;
    animation: slide-up-3d 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
    transform-origin: left center;
}
#test .part.three.out h2 {
    animation: slide-up-3d-out .5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

#test .part.three .inner {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(5, minmax(auto, 1fr));
    column-gap: 1rem;
}

#test .part.three main button {
    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-fff) / .02);
    border: 3px solid hsl(var(--bg-fff) / .08);
    padding: 1.5rem;

    transition: 0.3s ease;
}
#test .part.three main button:hover {
    background-color: hsl(var(--bg-fff) / .05);
    border-color: hsl(var(--bg-fff) / .1);
}
#test .part.three main button.selected {
    background-color: hsl(var(--bg-fff) / .06);
    border-color: hsl(var(--fs-accent));
}










@media screen and (max-width: 1200px) and (min-width: 1000px) {
    #test .part.one main ul li {
        column-gap: 6rem;
    } 
}

@media screen and (max-width: 1000px) {

    /* ------------------------ */
    /* ------- general --------- */
    /* ------------------------ */
    #test .progress-bar {
        bottom: unset;
        top: 0;
    }

    #test .part {
        padding-top: 6rem;
        padding-bottom: 2rem;

        width: var(--large-container-width) !important;
        height: 100%;

        display: grid;
        grid-template-rows: auto 1fr;
        row-gap: 2rem;
        column-gap: 0;
    }

    #test .part h2 {
        grid-row: 1;
        line-height: 1em;

        margin-inline: auto;
        text-align: center;
    }

    #test .part main {
        grid-row: 2;

        width: 100%;
        height: 100%;

        overflow-y: auto;
    }

    #test .part .next-button {
        grid-row: 3;
    }



    /* ------------------------ */
    /* ------- part 1 --------- */
    /* ------------------------ */
    #test .part .line {
        display: none !important;
    }

    #test .part.one main ul {
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-rows: repeat(7, minmax(auto, 1fr));
        grid-gap: 1.25rem;
    }

    #test .part.one main ul li {
        grid-template-columns: unset;
    
        display: grid;
        grid-template-rows: repeat(2, auto);
        row-gap: 0.2rem;
    }



    /* ------------------------ */
    /* ------- part 2 --------- */
    /* ------------------------ */
    #test .part.two main button h3 {
        font-size: 16px;
    }

    

    /* ------------------------ */
    /* ------- part 3 --------- */
    /* ------------------------ */
    #test .part.three .inner {
        column-gap: unset;
        grid-template-columns: unset;

        grid-template-rows: repeat(5, minmax(auto, 1fr));
        row-gap: 0.75rem;
    }
    
    #test .part.three main button h3 {
        font-size: 16px;
    }



}
















/*  keyframes  */

@keyframes rotate-in-left {
    from {
        opacity: 0;
        rotate: -80deg;
    }
}

@keyframes rotate-in-right {
    from {
        opacity: 0;
        rotate: 80deg;
    }
}

@keyframes slide-up-3d {
    from {
        opacity: 0;
        scale: 0.85;
        translate: 0 100%;
    }
    30% {
        opacity: 1;
        scale: 0..85;
        translate: 0 10%;
    }
    to {
        opacity: 1;
        scale: 1;
        translate: 0;
    }
}

@keyframes slide-up-3d-out {
    from {
        opacity: 1;
        scale: 1;
        translate: 0 -10%;
    }
    30% {
        opacity: 1;
        scale: 0.85;
        translate: 0 -10%;
    }
    to {
        opacity: 0;
        scale: 0.85;
        translate: 0 -100%;
    }
}

@keyframes slide-up-3d-px {
    from {
        opacity: 0;
        scale: 0.85;
        translate: 0 100px;
    }
    30% {
        opacity: 1;
        scale: 0..85;
        translate: 0 20px;
    }
    to {
        opacity: 1;
        scale: 1;
        translate: 0;
    }
}


@keyframes slide-up-3d-px-out {
    from {
        opacity: 1;
        scale: 1;
        translate: 0;
    }
    30% {
        opacity: 1;
        scale: 0.85;
        translate: 0 -20px;
    }
    to {
        opacity: 0;
        scale: 0.85;
        translate: 0 -80px;
    }
}