#admin .teams h2 {
    animation-delay: 0s;
}

#admin .team li.banner {
    position: relative;

    width: 250px;
    aspect-ratio: 192 / 358;
    object-fit: contain;

    display: block;
}

#admin .team li.banner * {
    margin-inline: auto;
    text-align: center;
}

#admin .team svg.banner-svg {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    overflow: visible;
}

#admin .team svg.banner-svg path {
    clip-path: inset(0 0 100% 0);

    animation: clip-path-0 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) var(--delay) forwards;
}

#admin .team svg.banner-svg rect {
    display: block;
    translate: 0 1rem;
    opacity: 0;

    animation: translate-0 0.45s cubic-bezier(0.215, 0.610, 0.355, 1) calc(var(--delay) + 400ms) forwards,
               opacity-1 0.45s ease-in calc(var(--delay) + 400ms) forwards;
}

#admin .team .banner p.axid {
    margin-top: 1.5rem;

    color: hsl(var(--bg-fff) / .5);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.15em;

        opacity: 0;
    animation: opacity-1 .7s ease calc(var(--delay) + 950ms) forwards;
}

#admin .team .banner h3 {
    --color: hsl(var(--bg-fff) / .7);

    margin-top: 2rem;

    width: max-content;
    max-width: 100%;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.10em;
    text-align: center;
    color: var(--color);

    padding-inline: 1.5rem;

    opacity: 0;
    animation: opacity-1 0.7s ease calc(var(--delay) + 500ms) forwards;
}

#admin .team .banner .mask-icon:first-of-type {
    display: block;
    margin-top: 2.5rem;

    width: 65px;
    height: 65px;
    object-fit: contain;

    opacity: 0;
    animation: opacity-1 0.7s ease calc(var(--delay) + 650ms) forwards;
}

#admin .team .banner .mask-icon:nth-of-type(2) {
    display: block;
    margin-top: 2rem;

    width: 40px;
    height: 40px;
    object-fit: contain;

    opacity: 0;
    animation: opacity-1 0.7s ease calc(var(--delay) + 800ms) forwards;
}

#admin .team .banner p:last-of-type {
    margin-top: 3.5rem;

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-transform: capitalize;

    opacity: 0;
    animation: opacity-1 .7s ease calc(var(--delay) + 950ms) forwards;
}