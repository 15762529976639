#add-sport {
    margin-top: 4rem;
    
    width: 100%;

    padding-bottom: 4rem;
}

#add-sport h1 {
    margin-inline: auto;

    width: max-content;
    font-weight: 500;
    letter-spacing: 0.10em;
    text-transform: uppercase;

    margin-bottom: 2rem;
}

#add-sport h2 {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

#add-sport form {
    margin-inline: auto;
    
    width: 800px;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

#add-sport form .state-button-wrap {
    grid-column: 1 / span 2;
}

#add-sport form .row {
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
}

#add-sport form .row.buttons {
    margin-bottom: 1rem;

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}

#add-sport form .row .input-wrap input,
#add-sport form .row .input-wrap label {
    animation-delay: 0s;
}

#add-sport form .row.delay {
    margin-top: 1rem;
}

#add-sport form .row.delay .input-wrap input,
#add-sport form .row.delay .input-wrap label {
    animation-delay: 1s;
}

#add-sport form .no-delay input,
#add-sport form .no-delay label {
    animation-delay: 0s !important;
}