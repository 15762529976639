/* no team */
#admin .no-team {
    background-color: hsl(var(--bg-fff) / .01);
    width: 100%;
    text-align: center;
    border: 3px solid hsl(var(--bg-fff) / .05);

    padding: 4rem;
}



/* has team */
#admin .teams {
    position: relative;

    width: 100%;
    background-color: hsl(var(--bg-fff) / .01);
    border: 3px solid hsl(var(--bg-fff) / .05);

    padding: 2rem;
}

#admin .teams h2 {
    color: hsl(var(--bg-accent));
}

#admin .teams .controls {
    width: 100%;
    height: auto;
    padding-inline: .75rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

#admin .teams .controls > div {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

#admin .teams .controls button {
    color: hsl(var(--bg-fff) / .6);
    transition: 0.3s ease;
}
#admin .teams .controls button:hover {
    color: hsl(var(--bg-accent));
}

#admin .teams .controls button svg {
    height: 20px;
    width: auto;
    display: flex;
    align-items: center;
}

#admin .teams .controls button:hover svg path {
    fill: hsl(var(--bg-accent));
}

#admin .team {
    width: 100%;

    opacity: 0;
    animation: opacity-1 0.35s ease var(--animation-delay) forwards;
}

#admin .team h2 {
    margin-bottom: 2rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

#admin .team ul#banners {
    --nb-banners: 5;

    position: relative;

    width: 100%;
    height: auto;
    
    display: grid;
    grid-template-columns: repeat(var(--nb-banners), 250px);
    column-gap: 3rem;

    padding-bottom: 2rem;
    padding-inline: 1rem;
    overflow-x: auto;
}
#admin .team ul#banners::-webkit-scrollbar {
    height: 10px;
    background-color: hsl(var(--bg-fff) / .1);
}
#admin .team ul#banners::-webkit-scrollbar-thumb {
    background-color: hsl(var(--bg-fff) / .2);
}   

#admin .team > p.hold-shift {
    display: none;
}

@media (hover: hover) and (pointer: fine) {
    #admin .team ul#banners {
        padding-bottom: 3rem;
    }

    #admin .team > p.hold-shift {
        display: unset;
        position: absolute;
        left: 50%;
        bottom: .5rem;
        translate: -50%;

        font-size: 10px;
    }
}