#contact-us {
    position: relative;
    padding-top: calc(var(--navbar-height) * 1.2);

    min-height: 100%;

    display: none;
}

#contact-us .form-cont {
    width: 100%;
    padding-inline: var(--mob-side-padding);
}

#contact-us h1 {
    margin-left: 0rem;
    text-align: center;
    letter-spacing: 0.10em;
    margin-bottom: 1.5rem;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

#contact-us form {
    width: 100%;
}

#contact-us button.border-button {
    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

#contact-us > p {
    margin-top: 4rem;
    padding-bottom: 2rem;
    text-align: center;

    font-size: 14px;
    color: hsl(var(--fs-fff) / 0.2);

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}


#contact-us-line-1 {
    position: fixed;
    top: 0;
    left: var(--mob-side-padding);

    clip-path: inset(0 0 100% 0);
}

#contact-us-line-2 {
    position: fixed;
    top: 0;
    right: var(--mob-side-padding);

    clip-path: inset(100% 0 0 0);
}

@media screen and (min-width: 750px) {
    #contact-us form {
        width: var(--form-width);
        margin-inline: auto;
    }

    #contact-us-line-1 {
        left: calc(50vw - var(--form-width) / 2);
    }

    #contact-us-line-2 {
        right: calc(50vw - var(--form-width) / 2);
    }
}

@media screen and (min-height: 700px) {
    #contact-us {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }
}



/* animation out */
#contact-us.out h1 {
    opacity: 1;
    animation: opacity-0 0.5s ease 0.3s forwards;
}

#contact-us.out > p {
    opacity: 1;
    animation: opacity-0 0.5s ease 0.3s forwards;
}

#contact-us.out button.border-button {
    opacity: 1;
    animation: opacity-0 0.5s ease 0.3s forwards;
}

#contact-us.out #contact-us-line-1 {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-t 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) .9s forwards,
               line-bg-out 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) .9s forwards;
}

#contact-us.out #contact-us-line-2 {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-b 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) .9s forwards,
               line-bg-out 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) .9s forwards;
}