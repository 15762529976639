#profile .masks {
    position: relative;

    width: 100%;
    background-color: hsl(var(--bg-fff) / .01);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    opacity: 0;
    animation: opacity-1 0.35s ease var(--animation-delay) forwards;
}


/* no masks */
#profile .no-masks {
    background-color: hsl(var(--bg-fff) / .01);
    border-bottom: 3px solid hsl(var(--bg-fff) / 0.05);
    width: 100%;
    text-align: center;

    padding: 4rem;

    opacity: 0;
    animation: opacity-1 0.35s ease var(--animation-delay) forwards;
}

#profile .no-masks p {
    margin-bottom: 2rem;
}


/*  left  */
#profile .masks .left {
    width: 100%;
    height: 100%;

    padding: 2rem;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: minmax(auto, 1fr) auto;
    gap: 1.5rem;
}

#profile .masks .left #mask-separator {
    left: calc(50% - 1.5px);
    height: calc(100% - 4rem);
    top: 2rem;

    clip-path: inset(100% 0 0 0);
}

#profile .masks .left h3 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: 1em;
    color: hsl(var(--fs-accent));
}

#profile .masks .left h4 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: hsl(var(--fs-fff) / .6);
    text-transform: uppercase;
}
#profile .masks .left li:first-of-type h4 {
    color: hsl(var(--white) / 0.85);
}

#profile .masks .left .helper {
    width: 20px;
    height: 20px;
    background-color: #222;
    border: 3px solid hsl(var(--bg-fff) / 0.1);
    font-size: 9px;
    color: hsl(var(--fs-fff) / .7);

    transition: 0.3s ease;
}
#profile .masks .left .helper:hover {
    color: hsl(var(--fs-fff));
    border-color: hsl(var(--bg-fff) / .3);
}

#profile .masks .left .card-01 .pu-card {
    position: absolute;
    z-index: -1;
    bottom: calc(100% + 10px);
    left: -2.5px;

    width: calc(100% + 5px);
    display: none;
}

#profile .masks .test-masks {
    grid-row: 1;
    grid-column: 1 / span 2;
    
    display: grid;
    grid-template-rows: auto minmax(280px, 1fr);
    row-gap: 1rem;
}

#profile .masks .mask-grid {
    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-fff) / 0.05);

    padding: 3px;
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 3fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 3px;

    overflow-x: auto;
}

#profile .masks .mask-grid li:first-of-type {
    grid-row: span 2;
    min-width: 175px;
}

#profile .masks .mask-grid li.card-01.active,
#profile .masks .mask-grid li:first-of-type.active {
    border: 3px solid hsl(var(--bg-accent)) !important;
}

#profile .masks .mask-grid li:first-of-type svg {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

#profile .masks .mask-grid li {
    min-width: 135px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 3px solid transparent;
    user-select: none;
    cursor: pointer;

    transition: 0.3s ease;
}
#profile .masks .mask-grid li svg {
    width: 25px;
    height: 25px;
    object-fit: contain;
}
#profile .masks .mask-grid li p {
    font-size: 13px;
    line-height: 1em;
    text-align: center;
}
#profile .masks .mask-grid li > div {
    display: grid;
    row-gap: 6px;
    margin-bottom: 0.5rem;
}

#profile .masks .left .big-number {
    font-size: 60px;
    font-weight: 500;
    color: hsl(var(--fs-accent));
    line-height: 1em;
}
#profile .masks .left .medium-number {
    font-size: 35px;
    font-weight: 500;
    color: hsl(var(--fs-accent));
    line-height: 1em;
}





#profile .masks .colors {
    grid-row: 2;
    grid-template-columns: 1;

    display: grid;
    grid-template-rows: auto 130px;
    gap: 1rem;
}

#profile .masks .colors .card-01 {
    position: relative;
    
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1.5rem;
    user-select: none;
}

#profile .masks .colors .card-01 .square {
    border: 3px solid hsl(var(--bg-fff) / 0.05);
    padding: 2px;
    
    display: grid;
    grid-template-rows: var(--pred) var(--pyellow) var(--pgreen) var(--pblue);
    row-gap: 4px;
}

#profile .masks .colors .card-01 .values {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 1rem;
}

#profile .masks .colors .card-01 .values ul {
    font-size: 13px;
    letter-spacing: 0.05em;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#profile .masks .colors .card-01 .values ul:last-of-type {
    text-align: right;
    color: hsl(var(--fs-accent));
}




#profile .masks .grit {
    grid-row: 2;
    grid-template-columns: 2;

    display: grid;
    grid-template-rows: auto 130px;
    gap: 1rem;
}

#profile .masks .grit .card-01 {
    position: relative;
    z-index: 5;
    
    display: grid;
    grid-template-columns: auto minmax(auto, 1fr);
    column-gap: 1.5rem;
    align-items: center;

    user-select: none;
}

#profile .masks .grit .card-01 p {
    font-size: 14px;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

#profile .left .card-01.hovered .pu-card {
    z-index: 10;
    display: block;
    min-width: 290px;
    overflow: hidden;
}

#profile .left .card-01 .inner {
    max-height: 350px;
    background-color: #1a1a1a;
    border: 3px solid hsl(var(--bg-fff) / .05);
    padding: 1rem;
    overflow-y: auto;

    translate: 0 101%;
    animation: translate-0 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}


#profile .left .card-01 .pu-card .inner p {
    font-size: 14px;
}


/*  center  */
#profile .masks .center {
    position: relative;

    height: 100%;
    border-inline: 3px solid hsl(var(--bg-fff) / .05);

    padding: .75rem 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(7, 1fr);
    place-items: center;
}

#profile .masks .center .mask {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#profile .masks .center .mask p {
    font-size: 12px;
    color: hsl(var(--fs-fff) / .6);
    transition: 0.3s ease;
}
#profile .masks .center .mask.active p,
#profile .masks .center .mask:hover p {
    color: hsl(var(--bg-accent));
}

#profile .masks .center .mask-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
}
#profile .masks .center .mask .mask-icon path {
    fill: hsl(var(--bg-fff) / .6);
    transition: 0.3s ease;
}
#profile .masks .center .mask:hover .mask-icon path {
    fill: hsl(var(--bg-accent));
}
#profile .masks .center .mask.active .mask-icon path {
    fill: hsl(var(--bg-accent));
}

#profile #indicator {
    --mask-index: 0.75;
    --block-height: calc((100% - 1.5rem) / 7);

    position: absolute;
    top: calc(var(--block-height) * (var(--mask-index) - 0.5) + 1rem);
    left: 100%;
    translate: 0 calc(-50% - 10px);

    height: 25px;

    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); 
    opacity: 0;
    animation: opacity-1 0.5s ease forwards;
}


/*  right  */
#profile .masks .right {
    padding: 2rem;

    width: 100%;
    height: 100%;
    max-height: 570px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr;
}

#profile .masks .no-mask {
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

#profile .masks .right .masks-right-header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#profile .masks .right h2 {
    letter-spacing: 0.05em;
    text-transform: uppercase;

    opacity: 0;
    animation: opacity-1 0.5s ease forwards;
}

#profile .masks .right .masks-right-header .mask-icon {
    translate: 25% -25%;
    
    height: 30px;
    width: 30px;
    object-fit: contain;
}

#profile .masks .right .body {
    width: 100%;
    height: 100%;
    color: hsl(var(--fs-fff) / .6);

    padding-right: 20px;
    overflow-y: auto;
}

#profile .masks .right .body .body-block h3 {
    margin-bottom: .3rem;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: hsl(var(--fs-fff));
    opacity: 1;
    transform: translateY(0%);
    animation: none;
}

#profile .masks .right .body .body-block h4 {
    color: hsl(var(--fs-fff));
    letter-spacing: 0.02em;
    margin-top: 0.50rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

#profile .masks .right .body .body-block ul {
    list-style: none;
}
#profile .masks .right .body .body-block ul li {
    position: relative;
    padding-left: 20px;
}
#profile .masks .right .body .body-block ul li::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 6px;
    transform: translate(-50%, -50%);

    width: 5px;
    height: 5px;
    background-color: hsl(var(--bg-accent));
}

#profile .masks .right .body .body-block {
    margin-bottom: 2rem;
    font-size: 14px;
    color: hsl(var(--white) / 0.7);
}



@media screen and (min-width: 1100px) and (max-width: 1250px) {
    #profile .masks .left {
        grid-template-columns: repeat(2, 1fr);
    }
    
    #profile .masks .grit .card-01 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #profile .masks .grit .card-01 > p {
        display: none;
    }
}


@media screen and (max-width: 1100px) {
    #profile .masks {
        height: unset;

        grid-template-columns: 100%;
        grid-template-rows: auto 100px auto;
    }

    #profile .masks .left {
        padding: 1.5rem;
    }

    #profile .masks .center {
        position: sticky;
        position: -webkit-sticky;
        top: -3px;
        z-index: 2;
        
        width: 100%;
        height: 100px;
        background-color: #151515;
        border-inline: none;
        border-top: 3px solid hsl(var(--bg-fff) / .05);
        border-bottom: 3px solid hsl(var(--bg-fff) / .05);

        padding: 0;
        grid-template-columns: repeat(7, minmax(100px, calc(100% / 7)));
        grid-template-rows: 100%;
        overflow-x: auto;
    }

    #profile #indicator {
        display: none;
    }

    #profile .masks .no-mask {
        padding: 3rem 2rem;
    }

    #profile .masks .right .masks-right-header .mask-icon {
        translate: 0 -12.5%;
    }

    #profile .masks .right {
        height: auto;
        max-height: unset;

        padding: 1.5rem;
    }

    #profile .masks .right h2 {
        margin-bottom: 2rem;
    }

    #profile .masks .right .body {
        overflow-y: unset;
    }

    #profile .masks .left .block .body .core {
        font-size: 11px;
        color: hsl(var(--fs-fff) / .6);
    }
}

@media screen and (max-width: 800px) {
 
    #profile .masks .left {
        grid-template-rows: minmax(auto, 1fr) auto auto;
    }

    #profile .masks .left .colors {
        grid-column: span 2;
    }

    #profile .masks .left .grit {
        grid-row: 3;
        grid-column: span 2;
    }

}