#admin {
    --header-height: 30px;
    --footer-height: 40px;
    --animation-delay: 1.25s;

    position: relative;
    padding-top: var(--profile-nav-height);

    width: 100%;
    height: 100%;
    
    display: none;
}

#admin .header {
    position: relative;
    
    width: 100%;
    height: 30px;

    padding-inline: var(--profile-padding);
    display: flex;
    align-items: center;
}

#admin .header h1 {
    margin-left: .75rem;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.10em;
    color: hsl(var(--fs-accent));

    margin-bottom: 0.25rem;

    opacity: 0;
    animation: opacity-1 0.5s ease .8s forwards;
}

#admin .header h1 span {
    margin-left: .5rem;
    font-size: 12px;
    color: hsl(var(--fs-fff) / .4);
}

#admin h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    line-height: 1em;
    color: hsl(var(--fs-accent));

    opacity: 0;
    animation: opacity-1 0.5s ease 1.1s forwards;
}

#admin .sub {
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 0.025em;
}



/*  main  */
#admin main {
    width: calc(100% - var(--profile-padding) * 2);
    height: calc(100% - var(--header-height) - var(--footer-height));
    margin-inline: auto;

    padding: 0.75rem;

    overflow-y: auto;
    transition-duration: 1s;

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}
#admin main::-webkit-scrollbar {
    display: none;
}

#admin main h2 {
    font-size: 14px;
    letter-spacing: .1em;
    color: hsl(var(--fs-fff) / .6);
    text-transform: uppercase;
}
#admin main > h2 {
    color: hsl(var(--fs-accent));
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#admin main h3 {
    font-size: 18px;
    font-weight: 500;
    color: hsl(var(--fs-fff) / .6);
}

#admin main h3 span {
    font-size: 12px;
    color: hsl(var(--fs-accent));
}

#admin main > .card-01 + .card-01 {
    margin-top: .75rem;
}

#admin main .no-org {
    width: 100%;
    height: 100%;

    overflow: hidden;
}

#admin main .no-org .slide {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#admin main .no-org .slide > p {
    font-size: 14px;
    display: flex;
}

#admin main h2 {
    animation-delay: 0s !important;
}

#admin main .no-org .slide > * + * {
    margin-top: 2rem;
}

#admin main .no-org h2 {
    font-size: 25px;
    color: hsl(var(--fs-accent));

}

#admin main .no-org .buttons {
    display: flex;
    column-gap: 2rem;
}

#admin main .no-org form {
    width: min(350px, 100%);

    display: grid;
    grid-template-rows: repeat(auto-fit, auto);
    row-gap: .75rem;
}

#admin main .no-org .slide .input-wrap > *  {
    animation-delay: 0s !important;
}


#admin main button {
    color: hsl(var(--fs-accent));
}
#admin main button:hover {
    color: hsl(var(--fs-accent) / .6);
}


#admin main .org-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#admin main .org-card > div {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}




/* footer */
#admin .logout {
    position: relative;
    z-index: 1;

    width: 100%;
    height: var(--footer-height);

    display: flex;
    align-items: center;
    justify-content: center;
}

#admin .logout button {
    display: flex;
    align-items: center;
    color: hsl(var(--fs-fff) / .6);

    opacity: 0;
    animation: opacity-1 0.5s ease 1.1s forwards;
}

#admin .logout button img {
    margin-left: .5em;
    opacity: 0.6;
}

#admin #footer-line {
    top: 0;
    left: 0;

    clip-path: inset(0 100% 0 0);
}



@media screen and (max-width: 1100px) {
    :root {
        --profile-padding: 1.5rem;
    }

    #admin main .org-card {
        flex-direction: column;        
        align-items: flex-start;
    }
    #admin main .org-card .right {
        margin-top: 1rem;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1rem;
    }

    #admin main .row-1 {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
    }

    #admin main .row-1 .picture {
        aspect-ratio: 5 / 3;
    }

    #admin main .row-1 > * + * {
        border-left: none;
        border-top: 3px solid hsl(var(--bg-fff) / .05);
    }
}





/*  misc  */
#admin #header-line {
    bottom: 0;

    clip-path: inset(0 0 0 100%);
}

#admin #left-line {
    top: 0;
    left: calc(var(--profile-padding) - 3px);

    clip-path: inset(100% 0 0 0);
}

#admin #right-line {
    top: 0;
    right: calc(var(--profile-padding) - 3px);

    clip-path: inset(100% 0 0 0);
}

#admin #row-1-line {
    bottom: 0;

    clip-path: inset(0 0 0 100%);
}

#admin #row-2-line {
    bottom: 0;

    clip-path: inset(0 100% 0 0);
}

#admin #row-2-v-line {
    left: 50%;
    translate: -50%;

    clip-path: inset(0 0 100% 0);
    animation-delay: 0.75s;
}


/*  profile animation out  */
#root:has(#admin.out) #profile-nav {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#admin.out main {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#admin.out .header h1 {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#admin.out .logout button {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#admin.out #header-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-r 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#admin.out #footer-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-l 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#admin.out #left-line,
#admin.out #right-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-b 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#admin.out #row-1-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-r 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#admin.out #row-2-line {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-l 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards,
               line-bg-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}