#profile .basics {
    position: relative;

    width: 100%;
}

#profile .basics li {
    width: 100%;
    padding: 0.5em 0.75em;
    line-height: 1em;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
#profile .basics li.hl {
    background-color: hsl(var(--bg-fff) / 0.015);
}

#profile .basics li p {
    font-size: 14px;

    display: flex;
    align-items: center;
}

#profile .basics li p + p {
    margin-left: .75em;

    color: hsl(var(--fs-accent));
    overflow: hidden;
    text-overflow: ellipsis;
}

#basics-line {
    top: 0;
    left: 50%;
    translate: -50%;

    clip-path: inset(100% 0 0 0);
    animation-delay: 1.3s;
}