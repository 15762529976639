.center-modal {
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-111) / 0.5 );

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    animation: opacity-1 0.3s ease forwards;
}

.center-modal .modal {
    max-height: 95%;
    max-width: min(600px, 90%);
    background-color: hsl(var(--bg-111));
    border: 3px solid hsl(var(--bg-accent));
    text-align: center;

    padding: 2rem;
    padding-bottom: 4rem;

    opacity: 0;
    animation: slide-up-3d 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0.2s forwards;
}

.center-modal .modal.max-sized {
    padding: unset;
    padding-bottom: 2rem;
    height: 90%;
    max-width: 95%;
    aspect-ratio: 1.24;
}


.center-modal .modal h2 {
    margin-bottom: 2rem;

    opacity: 0;
    animation: opacity-1 0.5s ease .6s forwards !important;
}

.center-modal .modal > p {
    margin-bottom: 1rem;
    font-size: 14px;
    overflow-y: auto;

    opacity: 0;
    animation: opacity-1 0.5s ease .7s forwards;
}





.center-modal .close {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    translate: -50%;
    
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    animation: opacity-1 0.5s ease 0.8s forwards;
}

.center-modal .close p,
.center-modal .controls {
    font-size: 14px;
    letter-spacing: 0.1em;
    color: hsl(var(--fs-accent));
}

.center-modal .close:hover p,
.center-modal .controls button:hover {
    color: hsl(var(--fs-accent) / .7);
}

.center-modal .close img {
    margin-left: .3rem;

    width: 12px;
    height: 12px;
    object-fit: contain;
}
.center-modal .close:hover img {
    opacity: 0.6;
}