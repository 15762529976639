.range-input {
    --thumb-offset: 0;
    position: relative;
    
    width: 100%;
    height: 2rem;
    background-color: #181818;

    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.range-input .thumb {
    --part: calc(100% / 7);
    position: absolute;
    top: 0;
    left: calc(var(--part) * var(--thumb-offset) + (var(--part) / 2 - 1rem));

    height: 2rem;
    width: 2rem;
    background-color: hsl(var(--bg-accent));

    opacity: 0;
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.range-input .thumb.active {
    opacity: 1;
}

.range-input button {
    position: auto;
    z-index: 1;

    font-size: 13px;
    color: hsl(var(--fs-fff) / .6);

    transition: 0.3s ease;
}
.range-input button:hover {
    color: hsl(var(--fs-fff));
}
.range-input button.selected {
    color: hsl(var(--fs-fff));
    text-shadow: 1px 1px 2px #151515;
}

.range-input-wrap .helpers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}
.range-input-wrap span {    
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: hsl(var(--bg-fff) / .5);
}


@media screen and (max-width: 1000px) {
    .range-input button {
        font-size: 10px;
    }
}