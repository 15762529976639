/* variables */
:root {

    /* colors */
    --bg-000: 0 0% 0%;
    --bg-111: 0 0% 7%;
    --bg-151: 0 0% 8%;
    --bg-333: 0 0% 20%;
    --bg-fff: 0 0% 100%;
    --bg-accent: 29 100% 50%;

    --fs-fff: 0 0% 100%;
    --fs-accent: 29 100% 50%;
    
    --blue: #356dfc;
    --red: #ff0022;
    --yellow: #ffee00;
    --green: #00ff00;
    --orange: #ff7a00;

    /* widths */
    --container-width: clamp(300px, 80%, 1920px);
    --large-container-width: clamp(270px, 90%, 1920px);
    --navbar-height: 100px;
    --profile-nav-height: 80px;
    --mob-side-padding: 2rem;
    --mob-container-width: calc(100% - var(--mob-side-padding) * 2);
    --form-width: min(450px, 90%);
    --l-form-width: min(600px, 90%);

    --profile-padding: 3rem;
}


/* typogrpahy */
button, a {
    font-family: 'Poppins';
    font-size: 14px;
    display: block;
}

p {
    font-size: 1rem;
    color: hsl(var(--fs-fff) / 0.6);
}


/* utility classes */ 
.hidden {
    display: none !important;
}

.fs-accent {
    color: hsl(var(--fs-accent));
}
.fs-fff {
    color: hsl(var(--fs-fff));
}
.mt-2 {
    margin-top: 2rem;
}
.mt-1 {
    margin-top: 1rem;
}
.ml-1 {
    margin-left: 1rem;
}
.mt-05 {
    margin-top: 0.5rem;
}
.mt-75 {
    margin-top: 0.75rem;
}


/*  colors   */
.bg-red {
    background-color: var(--red);
}
.bg-yellow {
    background-color: var(--yellow);
}
.bg-green {
    background-color: var(--green);
}
.bg-blue {
    background-color: var(--blue);
}




/* components */
.menu-links > * {
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.15em;
    line-height: 2.5em;
}
.menu-links li a.active {
    position: relative;
    color: hsl(var(--fs-accent));
}
.menu-links li a::before {
    content: '';
    position: absolute;
    top: calc(100% - 7px);
    left: -1px;

    width: 100%;
    height: 3px;
    background-color: hsl(var(--bg-fff) / .2);

    clip-path: inset(0 100% 0 0);
    transition: 0.3s ease;
}
.menu-links li a.active::before,
.menu-links li a:hover:before {
    clip-path: inset(0 0 0 0);
}
.menu-links li a:hover:before {
    background-color: hsl(var(--bg-fff) / .3);
}
@media screen and (min-width: 1000px) {
    .menu-links li a {
        font-size: 14px;
    }
}

.black-overlay-25 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-000) / .25);

    opacity: 0;
    animation: opacity-1 0.3s ease forwards;
}

.line {
    position: absolute;
    background-color: hsl(var(--bg-fff) / 0.3);

    animation: line-in 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards ;
}
@media screen and (min-width: 1100px) {
    .line {
        position: absolute;
    }
}

.v-line {
    width: 3px;
    height: 100%;
}

.h-line {
    height: 3px;
    width: 100%;
    left: 0;
}

.border-button {
    padding: 1rem 1.5rem;
    min-width: 190px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    white-space: nowrap;
    background-color: hsl(var(--bg-fff) / .07);
    border: 3px solid hsl(var(--bg-fff) / .1); 
    transition: 0.5s cubic-bezier(0.215, 0.610, 0.355, 1);
    cursor: pointer;
}
.border-button.orange {
    color: hsl(var(--fs-accent));
    border-color: hsl(var(--bg-accent));
}
.border-button.min {
    min-width: unset;
}
.border-button.full,
.border-button.mob-full {
    width: 100%;
    text-align: center;
}
.border-button:hover {
    background-color: hsl(var(--bg-fff) / .12);
}
@media screen and (min-width: 1100px) {
    .border-button.mob-full {
        width: min-content;
    }
}

.padded-scrollbar {
    --width: 20px;
}
.padded-scrollbar.thin {
    --width: 10px;
}
.padded-scrollbar.ultra-thin {
    --width: 6px;
}
.padded-scrollbar::-webkit-scrollbar {
    width: var(--width);
    background-color: #171717;
    border-left: calc(var(--width) / 2) solid hsl(var(--bg-111));
}
.padded-scrollbar::-webkit-scrollbar-thumb {
    width: var(--width);
    background-color: hsl(var(--bg-fff) / .2);
    border-left: calc(var(--width) / 2) solid hsl(var(--bg-111));
}
.padded-h-scrollbar::-webkit-scrollbar {
    background-color: #151515;
    border: 2px solid #151515;
}
.padded-h-scrollbar::-webkit-scrollbar-thumb {
    height: 16px;
    border-top: 5px solid #151515;
    background-color: hsl(var(--bg-fff) / .3);
}


.row-between {
    display: flex;  
    justify-content: space-between;
} 


/*  cards  */
.card-01 {
    background-color: #171717;
    border: 3px solid hsl(var(--bg-fff) / 0.05);
    padding: 1rem;
}
.card-02 {
    background-color: #1a1a1a;
    border: 3px solid hsl(var(--bg-fff) / 0.05);
    padding: 1rem;
}

.card-01:hover {
    background-color: #1a1a1a;
}
.card-02:hover {
    background-color: #1c1c1c;
}

.copyrights {
    font-size: 14px;
    color: hsl(var(--fs-fff) / 0.2);
    text-align: center;
}


/* scrollbars */
@media (hover: hover) and (pointer: fine) {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: hsl(var(--bg-fff) / .1);
    }
    ::-webkit-scrollbar-thumb {
        background-color: hsl(var(--bg-fff) / .2);
    }   
}


/* responsiveness */
@media screen and (min-width: 1100px) {
    .mob-only {
        display: none !important;
    }
    .sm-only {
        display: none !important;
    }
}

@media screen and (min-width: 750px) and (max-width: 1099px) {
    .sm-only {
        display: none !important;
    }
    .dk-only {
        display: none !important;
    }

    :root {
        --mob-container-width: max(600px, 80%);
        --mob-side-padding: calc((100% - var(--mob-container-width)) / 2);
    }
}

@media screen and (max-width: 749px) {
    .sm-hide {
        display: none !important;
    }
    .dk-only {
        display: none !important;
    }
    .sm-hide {
        display: none !important;
    }
}



/* keyframes */
@keyframes clip-path-0 {
    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes clip-path-t {
    to {
        clip-path: inset(100% 0 0 0);
    }
}

@keyframes clip-path-r {
    to {
        clip-path: inset(0 100% 0 0);
    }
}

@keyframes clip-path-b {
    to {
        clip-path: inset(0 0 100% 0);
    }
}

@keyframes clip-path-l {
    to {
        clip-path: inset(0 0 0 100%);
    }
}

@keyframes opacity-1 {
    to {
        opacity: 1;
    }
}

@keyframes opacity-0 {
    to {
        opacity: 0;
    }
}

@keyframes translate-0 {
    to {
        translate: 0;
    }
}

@keyframes line-in {
    to {
        clip-path: inset(0 0 0 0);
        background-color: hsl(var(--bg-fff) / 0.05);
    }
}

@keyframes line-bg-out {
    to {
        background-color: hsl(var(--bg-fff) / .2);
    }
}

@keyframes hex-path {
    to {
      stroke-dashoffset: 0px;
    }
}

@keyframes hex-path-out {
    to {
        stroke-dashoffset: -3675px;
    }
}

@keyframes translate-x--110 {
    to {
        translate: -110%;
    }
}

@keyframes translate-x-110 {
    to {
        translate: -110%;
    }
}

@keyframes translate-y-50 {
    to {
        translate: 0 50%;
    }
}

@keyframes translate-y--15vh {
    to {
        translate: 0 -15vh;
    }
}

@keyframes rotate-infinite {
    from {
        transform: rotate(0deg);
    }
    to {
       transform: rotate(360deg);
    }
}

@keyframes slide-up-3d {
    from {
        opacity: 0;
        scale: 0.85;
        translate: 0 100%;
    }
    30% {
        opacity: 1;
        scale: 0..85;
        translate: 0 10%;
    }
    to {
        opacity: 1;
        scale: 1;
        translate: 0;
    }
}