.file-input {
    margin-top: .75rem;
    position: relative;
    
    width: 100%;
    height: 3rem;
    background-color: hsl(var(--bg-fff) / 0.05);
    border: 3px solid hsl(var(--bg-fff) / 0.1);
    white-space: nowrap;

    padding-left: 1rem;
    padding-right: 4rem;
    display: flex;
    align-items: center;

    cursor: pointer;
}

.file-input input {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    translate: -1rem;
    opacity: 0;

    cursor: pointer;
}

.file-input button {
    position: absolute;
    z-index: 2;
    right: 1rem;
    top: 50%;
    translate: 0 -50%;

    transition: 0.2s ease;
}
.file-input button:hover {
    color: hsl(var(--fs-accent));
}

.file-input label {
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;

    cursor: pointer;
}


.file-input .mod-button img {
    visibility: visible;
    
    position: absolute;
    z-index: 5000;
    top: 50%;
    right: .15rem;
    translate: -8px -50%;

    width: 17px;
    height: 17px;
    object-fit: contain;

    opacity: 1;
    animation: rotate-infinite 0.7s linear infinite;
}