.masks-block {
    position: relative;
    padding: 2rem 1.5rem;

    display: none;
}

.masks-block h2 {
    margin-bottom: .75rem;
    line-height: 1em;

    opacity: 0;
    translate: 0 1rem;
    animation: opacity-1 0.5s ease 0.2s forwards,
               translate-0 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0.2s forwards;
}

.masks-block p {
    opacity: 0;
    translate: 0 1rem;
    animation: opacity-1 0.5s ease 0.25s forwards,
               translate-0 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0.25s forwards;
}

.masks-block p + p {
    margin-top: 1rem;

    animation: opacity-1 0.5s ease 0.3s forwards,
               translate-0 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0.3s forwards;
}

.masks-block .children {
    margin-top: 1.5rem;

    opacity: 0;
    animation: opacity-1 0.5s ease 0.3s forwards,
               translate-0 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0.3s forwards;
}



.masks-block .special-line {
    position: absolute;
    bottom: 0;

    background-color: hsl(var(--bg-fff) / .3);
    height: 3px;
}
.masks-block .special-line.left {
    left: 100%;
    right: 0;
    animation: special-line-left 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}
.masks-block .special-line.right {
    right: 100%;
    left: 0;
    animation: special-line-right 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

.masks-block .special-line.out,
.masks-block p.out,
.masks-block h2.out,
.masks-block .children.out {
    animation: none;
}



@media screen and (min-width: 1100px) {
    .masks-block {
        padding: 3rem 3rem;
    }

    .masks-block h2 {
        font-size: 26px;
        font-weight: 600;
    }

    .masks-block p {
        font-size: 18px;
    }
}


.out .masks-block h2 {
    translate: 0;
    opacity: 1;
    animation: opacity-0 0.5s ease 0.3s forwards;
}

.out .masks-block p {
    translate: 0;
    opacity: 1;
    animation: opacity-0 0.5s ease 0.5s forwards;
}

.out .masks-block h2.out,
.out .masks-block p.out,
.out .masks-block .children {
    animation: none;
    translate: 0;
    opacity: 0;
}

.out .special-line.left {
    background-color: hsl(var(--bg-fff) / .05);
    left: 0;

    animation: special-line-left-out 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}
.out .special-line.right {
    background-color: hsl(var(--bg-fff) / .05);
    right: 0;

    animation: special-line-right-out 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}


@keyframes special-line-left {
    to {
        background-color: hsl(var(--bg-fff) / .05);
        left: 0;
    }
}
@keyframes special-line-right {
    to {
        background-color: hsl(var(--bg-fff) / .05);
        right: 0;
    }
}
@keyframes special-line-left-out {
    to {
        background-color: hsl(var(--bg-fff) / .1);
        left: 100%;
    }
}
@keyframes special-line-right-out {
    to {
        background-color: hsl(var(--bg-fff) / .1);
        right: 100%;
    }
}