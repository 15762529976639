#profile-nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    width: 100%;
    height: var(--profile-nav-height);

    padding-inline: calc(var(--profile-padding) + 0.75rem);
    display: flex;
    align-items: center;
    justify-content: space-between;

    opacity: 0;
    animation: opacity-1 .5s ease 1s forwards;
}

#profile-nav #logo {
    position: fixed;
    top: calc(var(--profile-nav-height) / 2);
    left: 50%;
    translate: -50% -50%;

    display: block;
    width: 45px;
    height: 45px;
}

#profile-nav #logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}