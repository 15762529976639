#profile main .sports {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

#profile main .sports-header {
    padding: 0.75rem; 

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#profile main .sports-header a {
    font-size: 14px;
    color: hsl(var(--fs-fff) / .6);
}


#profile .sports ul {
    padding-inline: 0.75rem;
    position: relative;

    width: 100%;

    margin-bottom: 1rem;
    cursor: pointer;
}

#profile .sports ul li {
    width: 100%;
    padding: 0.5em 0.75em;
    line-height: 1em;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
#profile .sports ul li.hl {
    background-color: hsl(var(--bg-fff) / 0.015);
}

#profile .sports ul li p {
    font-size: 14px;

    display: flex;
    align-items: center;
}

#profile .sports ul li p + p {
    margin-left: .75em;

    color: hsl(var(--fs-accent));
    overflow: hidden;
    text-overflow: ellipsis;
}

#sports-line {
    top: 0;
    left: 50%;
    translate: -50%;

    clip-path: inset(100% 0 0 0);
    animation-delay: 1.3s;
}

#profile .sports .controls {
    margin-bottom: 3px;
    width: 100%;
    height: 3.2rem;
    border-top: 3px solid hsl(var(--bg-fff) / 0.05);
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#profile .sports .controls .title {
    color: hsl(var(--fs-accent));
    letter-spacing: 0.05em;
    
}

#profile .sports .controls .left,
#profile .sports .controls .right {
    height: 100%;
    aspect-ratio: 5 / 4;
    background-color: hsl(var(--bg-fff) / 0.01);
    font-size: 24px;
    color: hsl(var(--fs-fff) / 0.7);

    display: grid;
    place-items: center;

    transition: 0.2s ease;
}
#profile .sports .controls .left:hover,
#profile .sports .controls .right:hover {
    background-color: hsl(var(--bg-fff) / 0.02);
    color: hsl(var(--fs-accent));
}

#profile .sports .controls .left {
    border-right: 3px solid hsl(var(--bg-fff) / 0.05);
}
#profile .sports .controls .right {
    border-left: 3px solid hsl(var(--bg-fff) / 0.05);
}

#profile main .edit-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-fff) / 0.01);
    color: hsl(var(--fs-accent));

    display: grid;
    place-items: center;

    cursor: pointer;
    transition: 0.2s ease;
}
#profile main .edit-overlay:hover {
    background-color: hsl(var(--bg-fff) / 0.02);
}


/* organization cell */
#profile main .org-cell {
    position: relative;
    padding: .75rem;

    display: grid;
    grid-template-rows: auto 1fr;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

#profile main .org-cell h2 {
    height: min-content;
}

#profile main .org-cell a:not(.edit-overlay) {
    width: 100%;
    height: 100%;
    min-height: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#profile main .org-cell a:not(.edit-overlay) h3 {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: hsl(var(--fs-accent));
}

#profile main .org-cell a:not(.edit-overlay) p {
    margin-top: 1rem;
    font-size: 12px;
}