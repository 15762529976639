#password-page {
    position: relative;
    
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

#password-page > a img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

#password-page main {
    margin-inline: auto;
    width: min(450px, 85%);
}

#password-page main h1 {
    font-weight: 500;
}

#password-page form {
    margin-top: 1rem;
}
#password-page form > * + * {
    margin-top: 1rem;
}