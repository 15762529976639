.text-area {
    position: relative;
}

.text-area textarea {
    resize: none;
    display: block;

    width: 100%;
    height: 12em;
    border: none;
    background-color: transparent;

    font-family: 'Poppins';
    font-size: var(--input-fs);
    line-height: 1.3em;
    color: hsl(var(--bg-fff));
    border: 3px solid hsl(var(--bg-fff) / .1);

    padding: 1rem;

    clip-path: inset(100% 0 0 0);
    animation: clip-path-0 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 1s forwards;
}
.text-area textarea:focus {
    outline: none;
    background-color: hsl(var(--bg-fff) / .05);
    border: 3px solid hsl(var(--bg-fff) / .3);
}

.text-area label {
    position: absolute;
    z-index: 1;
    top: 1.5em;
    left: 16px;
    transform: translateY(-0.7em);

    font-weight: 500;
    padding-bottom: 1em;
    color: hsl(var(--bg-fff) / .7);

    transition: .3s ease;

    pointer-events: none;
    user-select: none;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

.text-area.done label {
    top: 0;
    background-color: hsl(var(--bg-111));
    padding-inline: 0.5em;
    font-size: 12px;
    opacity: 0.6;
    padding-bottom: 0;
}
    
.text-area span {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 12px;
    color: var(--fc-light);

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}


.out .text-area textarea {
    clip-path: inset(0 0 0 0);
    animation: clip-path-t 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0s forwards;
}

.out .text-area label,
.out .text-area span {
    opacity: 1;
    animation: opacity-0 0.5s ease 0s forwards;
}