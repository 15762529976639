.title-carousel {
    position: relative;
    top: 20%;
    left: 50%;
    translate: -50%;
    
    max-width: 350px;
    height: 100px;

    overflow: hidden;
    transition: 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sports-modal:has(.input-roulette.down) .title-carousel {
    top: 4rem;
}

.title-carousel .inner {
    --step: 0;

    translate: 0 calc(-100px * var(--step));
    transition: 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.title-carousel .inner h1 {
    height: 100px;
    display: grid;
    place-items: center;

    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: hsl(var(--fs-accent));
}


.sports-modal .join {
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    max-width: 350px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    row-gap: 2rem;
}

.sports-modal .join p {
    text-align: center;
    
    opacity: 0;
    animation: opacity-1 0.5s ease 0.5s forwards;
}

.sports-modal .join .input-wrap {
    width: 350px;
}

.sports-modal .join .state-button-wrap {
    opacity: 0;
    animation: opacity-1 0.5s ease 0.5s forwards;
}