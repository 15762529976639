#masks {
    position: relative;

    width: 100%;


}

#masks .masks {
    position: relative;
    margin-inline: auto;

    width: 100px;
    height: 30vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

#masks .masks img {
    width: 60px;

    margin-bottom: 1rem;

    translate: 0 -15vh;
    opacity: 0;
    animation: translate-0 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards,
               opacity-1 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards;
}

#masks #line-1 {
    position: absolute;
    left: 0;

    clip-path: inset(0 0 100% 0);
}

#masks #line-2 {
    position: absolute;
    right: 0;

    clip-path: inset(0 0 100% 0);
}

#masks .header {
    position: relative;

    width: 100%;
    padding: 1.5rem 2rem;
    text-align: center;
}

#masks h1 {
    font-size: 7vw;
    line-height: 1em;
    margin-bottom: .5rem;

    opacity: 0;
    animation: opacity-1 0.5s ease 0.8s forwards;
}

#masks .header p {
    margin-inline: auto;

    width: 70%;
    font-size: 12px;
    color: hsl(var(--fs-accent));

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}

#masks #line-3 {
    position: absolute;
    top: 0;

    clip-path: inset(0 0 0 100%);
}

#masks #line-4 {
    position: absolute;
    bottom: 0;

    clip-path: inset(0 100% 0 0);
}

#masks main {
    position: relative;
    margin-inline: auto;

    width: var(--mob-container-width);
    min-height: 100vh;
}

#masks #line-5 {
    position: absolute;
    top: 0;
    left: 0;

    clip-path: inset(100% 0 0 0);
}

#masks #line-6 {
    position: absolute;
    top: 0;
    right: 0;

    clip-path: inset(100% 0 0 0);
}

#masks p.copyrights {
    margin-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;

    font-size: 14px;
    color: hsl(var(--fs-fff) / 0.2);

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}


@media screen and (min-width: 1100px) {
    #masks h1 {
        font-size: 50px;
        letter-spacing: 0.15em;
    }

    #masks .header p {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.05em;
    }

    #masks main {
        width: var(--container-width);
    }
}

#masks.out .masks img {
    translate: 0;
    opacity: 1;
    animation: translate-y--15vh 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards,
               opacity-0 0.5s ease forwards;
}

#masks.out h1 {
    opacity: 1;
    animation: opacity-0 0.5s ease 0.1s forwards;
}

#masks.out .header p {
    opacity: 1;
    animation: opacity-0 0.5s ease 0.2s forwards;
}

#masks.out #line-1,
#masks.out #line-2,
#masks.out #line-3,
#masks.out #line-4,
#masks.out #line-5,
#masks.out #line-6 {
    clip-path: inset(0 0 0 0);
    background-color: hsl(var(--bg-fff) / 0.05);
}

#masks.out #line-1,
#masks.out #line-2 {
    animation: clip-path-b 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards;
}

#masks.out #line-3 {
    animation: clip-path-l 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) .7s forwards;
}
#masks.out #line-4 {
    animation: clip-path-r 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) .7s forwards;
}

#masks.out #line-5 {
    animation: clip-path-t 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) .7s forwards;
}
#masks.out #line-6 {
    animation: clip-path-t 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) .7s forwards;
}