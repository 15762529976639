#auth-verify {
    position: relative;

    width: 100%;
    min-height: 100%;


    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

#auth-verify main {
    position: relative;

    width: 80%;
    margin-inline: auto;
}

#auth-verify h1 {
    margin-left: 1.5rem;

    letter-spacing: 0.10em;
    margin-bottom: .5rem;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.3s forwards;
}

#auth-verify main p:first-of-type {
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.4s forwards;
}

#auth-verify form {
    width: 100%;
}

#auth-verify form .form-button-wrap {
    grid-column-start: 3;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.6s forwards;
}

#auth-verify form button {
    width: 100%;
    min-width: unset;
    height: 100%;
} 

#auth-verify main > p {
    margin-top: 3rem;
    margin-left: 1.5rem;
    font-size: 14px;

    display: flex;

    opacity: 0;
    animation: opacity-1 0.5s ease 1.5s forwards;
}
#auth-verify main > p + p {
    margin-top: 1rem;
}

#auth-verify main > p button,
#auth-verify main > p a {
    margin-left: 0.3rem;
    color: hsl(var(--fs-accent));
}

#auth-verify > p {
    position: absolute;
    bottom: 3rem;
    left: 10%;
    margin-left: 1.5rem;

    font-size: 12px;
    color: hsl(var(--fs-fff) / 0.2);

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}


#auth-verify .nb-input {
    width: 100%;
    height: 4em;
    font-family: 'Poppins';
    font-size: var(--input-fs);
    font-weight: 500;
    text-align: center;
    border: 3px solid hsl(var(--bg-fff) / .1);
    border-radius: 0;
    background-color: hsl(var(--bg-fff) / 0);
    color: var(--fs-fff);
    padding-inline: 1em;

    transition: color 0.3s ease;
}
#auth-verify .nb-input:focus {
    outline: none;
    background-color: hsl(var(--bg-fff) / .05);
    border-color: hsl(var(--bg-fff) / 0.3);
}

#auth-verify .line#v-line-1 {
    --left: 0;

    left: calc(var(--left) * 1px);

    background-color: hsl(var(--bg-fff) / .3);
    clip-path: inset(100% 0 0 0);
}

#auth-verify .line#v-line-2 {
    left: calc(var(--left) * 1px);
    background-color: hsl(var(--bg-fff) / .3);
    clip-path: inset(100% 0 0 0);
}

#auth-verify .line#v-line-3 {
    left: 10%;
    background-color: hsl(var(--bg-fff) / .3);
    clip-path: inset(0 0 100% 0);
}

@media screen and (min-width: 750px) {
    #auth-verify main {
        width: var(--l-form-width);
    }

    #auth-verify form .form-button-wrap {
        grid-column-start: unset;
    }

    #auth-verify .line#v-line-3 {
        top: 0;
        left: calc((100% - var(--l-form-width)) / 2);
    }

    #auth-verify > p {
        left: calc((100% - var(--l-form-width)) / 2);
    }

    #auth-verify main p:first-of-type {
        padding-right: 15%;
    }
}



#auth-verify.out .nb-input {
    clip-path: inset(0 0 0 0);
    animation: clip-path-t 0.5s cubic-bezier(0.215, 0.610, 0.355, 1) 0s forwards;
}

#auth-verify.out .form-button-wrap,
#auth-verify.out main > p,
#auth-verify.out main > p,
#auth-verify.out > p,
#auth-verify.out main h1 {
    opacity: 1;
    animation: opacity-0 0.5s ease forwards;
}

#auth-verify.out .line#v-line-1,
#auth-verify.out .line#v-line-2,
#auth-verify.out .line#v-line-3 {
    clip-path: inset(0 0 0 0);
    background-color: hsl(var(--bg-fff) / .05);
}
#auth-verify.out .line#v-line-1 {
    animation: clip-path-b 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.9s forwards,
               line-bg-out 0.5s ease 0.9s forwards;
}
#auth-verify.out .line#v-line-2 {
    animation: clip-path-b 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.9s forwards,
               line-bg-out 0.5s ease 0.9s forwards;
}
#auth-verify.out .line#v-line-3 {
    animation: clip-path-t 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.9s forwards,
               line-bg-out 0.5s ease 0.9s forwards;
}