#settings {
    position: fixed;
    z-index: 1500;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);


    opacity: 0;
    animation: opacity-1 0.2s ease forwards;
}

#settings .panel * {
    animation-delay: 0s !important;
}

#settings .panel {
    position: absolute;
    top: 0;
    right: 0;

    width: min(400px, 100%);
    height: 100%;
    background-color: hsl(var(--bg-111));

    padding: 2rem;
    overflow-y: auto;

    translate: 105%;
    animation: translate-0 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) .1s forwards;
}

#settings .panel-header {
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#settings .panel-header h2 {
    animation-delay: 0s;
}

#settings .panel-header a {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.08em;
    color: hsl(var(--bg-fff) / .6);
    
    transition: 0.3s ease;
}
#settings .panel-header a:hover {
    color: hsl(var(--fs-fff) / 0.85);
}

/* block */
#settings .panel .block {
    margin-top: 2rem;
}

#settings .panel .block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.25rem;
}

#settings .panel h3 {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: hsl(var(--fs-fff) / 0.7);
}

#settings .panel .block-header button {
    font-size: 12px;
    color: hsl(var(--fs-accent));
}

#settings .panel .input-wrap,
#settings .panel .text-area {
    margin-top: .75rem;
}

#settings .panel .dd {
    margin-top: .75rem;
}

#settings .panel .border-button {
    margin-top: 1rem;
}

#settings .panel .sport {
    padding: 1rem;
    background-color: hsl(var(--bg-fff) / .02);
    border: 3px solid hsl(var(--bg-fff) / .1);

    margin-bottom: .75rem;

    cursor: pointer;
    transition: 1s ease;
}
#settings .panel .sport:hover {
    background-color: hsl(var(--bg-fff) / .05);
}

#settings .panel h4 {
    font-weight: 500;
    letter-spacing: 0.05em;
    color: hsl(var(--fs-accent));

    margin-bottom: .5rem;
}

#settings .panel .sport li {
    padding: .25rem 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

#settings .panel .sport li p:first-child {
    font-size: 14px;
}

#settings .panel .sport li p:last-child {
    color: hsl(var(--fs-fff));
    text-align: right;
    line-height: 1.1em;
}

#settings .panel .sport .buttons {
    margin-top: 1rem;
    display: inline-flex;
    gap: 1rem;
}

#settings .panel .sport .buttons button,
#settings .no-org button {
    color: hsl(var(--fs-accent));
    transition: 0.3s ease;
}

#settings .no-org button {
    width: 100%;
    text-align: center !important;
}

#settings .panel .sport .buttons button:hover,
#settings .no-org button:hover {
    color: hsl(var(--fs-accent) / .7);
}

#settings .panel .team-card + .no-org {
    margin-top: .75rem;
}


#settings .panel ul.sport.org .buttons button {
    width: 100%;
    text-align: center;
    color: hsl(var(--fs-accent) / .6);
}
#settings .panel ul.sport.org button:hover {
    color: hsl(var(--fs-accent));
}

#settings .panel .team-card {
    width: 100%;
    background-color: hsl(var(--bg-fff) / .02);
    border: 3px solid hsl(var(--bg-fff) / 0.1);
    padding: 1rem;

    
}
#settings .panel .team-card + .team-card {
    margin-top: .75rem;
}

#settings .panel .team-card p {
    font-size: 14px;
}

#settings .panel .team-card .buttons {
    display: flex;
    column-gap: 1rem;
    row-gap: .25rem;
    flex-wrap: wrap;
}

#settings .panel .team-card button {
    flex-shrink: 0;
    text-align: left;
    color: hsl(var(--fs-fff) / .5);
    transition: 0.3s ease;
}
#settings .panel .team-card button:hover {
    color: hsl(var(--fs-accent));
}



@media screen and (max-width: 600px) {
    #settings .panel {
        width: 100%;
    }
}