/* mobile */
#home {
    position: relative;
    margin-inline: auto;

    width: var(--mob-container-width);
    height: 100%;
    min-height: 100%;
}

#home main {
    position: relative;
    padding-top: calc(var(--navbar-height) * 1.3);

    width: 100%;

    display: block;

}

#home main h1 {
    position: relative;
    z-index: 1;

    text-transform: uppercase;
    line-height: 1em;

    padding: .5rem .75rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    margin-bottom: 2rem;
}
#home main h1 #big-h1 {
    margin-top: 0.3rem;
    font-size: 9vw;
    letter-spacing: 0.1em;
    line-height: 1em;

    translate: -110%;
    clip-path: none;
    animation: translate-0 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1.1s forwards;
}

#home main h1 #small-h1 {
    font-size: 5vw;
    font-weight: 600;
    letter-spacing: 0.1em;
    line-height: 1em;

    translate: -110%;
    clip-path: none;
    animation: translate-0 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1.3s forwards;
}

#home main .left p {
    position: relative;
    padding: 0.2rem 16px 0.3rem 16px;
    color: hsl(var(--fs-fff) / 0.6);

    margin-bottom: 2rem;

    opacity: 0;
    translate: 0 50%;
    animation: translate-0 0.5s ease 1.3s forwards, 
               opacity-1 0.5s ease 1.3s forwards;
}

#home main .right {
    position: relative;
    width: 100%;
    aspect-ratio: 11 / 10;
    border: 3px solid hsl(var(--bg-fff) / 0.05);

    padding: 0.5rem;
    margin-bottom: 2rem;

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}

#home main .right img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    clip-path: inset(100% 0 0 0);
    animation: clip-path-0 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.9s forwards;
}


#home main .right span {
    font-size: 11px;
    letter-spacing: 0.15em;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    position: absolute;
    bottom: 0%;
    left: calc(100% + 3px);
    translate: 0 -10px;
    color: hsl(var(--fs-fff) / 0.3);
}

#home > p {
    margin-top: 4rem;
    padding-bottom: 2rem;
    text-align: center;

    font-size: 14px;
    color: hsl(var(--fs-fff) / 0.2);

    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}

#home-line-1 {
    position: fixed;
    top: 0;
    left: var(--mob-side-padding);
    clip-path: inset(100% 0 0 0);
}

#home-line-2 {
    position: fixed;
    top: 0;
    left: 50%;
    translate: -50%;
    clip-path: inset(0 0 100% 0);
}

#home-line-3 {
    position: fixed;
    top: 0;
    right: var(--mob-side-padding);
    clip-path: inset(100% 0 0 0);
} 

#home main h1::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;

    width: 3rem;
    background-color: hsl(var(--bg-accent));

    clip-path: inset(0 100% 0 0);
    animation: clip-path-0 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 1.1s forwards;
}

#home main .left p::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    width: 4px;
    background-color: hsl(var(--bg-fff) / .8);
}

#home main .border-button {
    opacity: 0;
    animation: opacity-1 0.5s ease 1s forwards;
}

/* desktop */
@media screen and (min-width: 1100px) {
   
    #home {
        position: relative;
        margin-inline: auto;
    
        width: var(--container-width);
        height: 100%;
    
        display: flex !important;
        align-items: center;
    }
    
    #home main {
        padding-top: 0;
    
        width: 100%;
        height: max(60%, 450px);
    
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr 65px;
    }
    
    #home main .left {
        grid-column: 1;
        grid-row: 1;
    }
    
    #home main .left h1 {
        z-index: 2;
        width: 60vw;
        height: 11vw;
        padding-left: 1.25rem;
    }

    #home main .left h1 #big-h1 {
        position: absolute;
        z-index: 2;
        font-size: 6vw;
    } 

    #home main .left h1 #small-h1 {
        position: absolute;
        z-index: 2;
        top: 7vw;
        font-size: 2.5vw;
    }

    #home main .left p {
        padding-right: 30%;
    }

    #home main .right {
        z-index: 1;
        grid-column: 2;
        grid-row: 1 / 3;
        margin-bottom: 0;
        aspect-ratio: none;
        height: 100%;
        background-color: hsl(var(--bg-111));
    }
    
    #home main .border-button {
        grid-column: 1;
        grid-row: 2;
        margin-bottom: 0.5rem;
    }  

    #home > p {
        position: absolute;
        left: 50%;
        translate: -50%;
        bottom: 1rem;
        padding-bottom: 0;
    }

    #home-line-1 {
        position: absolute;
        left: 0;
    }
    
    #home-line-3 {
        position: absolute;
        right: 0;
    } 
    
    #home main h1::before {
        width: 8%;
    }

    #home .arrows-cont {
        position: absolute;
        top: 85%;
        left: 0;
        overflow: hidden;
    }

    #home .arrows-cont img {
        height: 4vh;

        translate: -100%;
        animation: translate-0 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 1s forwards;
    }

    #home .hex-svg {
        position: absolute;
        top: 10%;
        z-index: 0;
        right: 0%;
        height: 90%;
        translate: 50%;
    }

    #home .hex-svg .hex-path {
        stroke-dasharray: 3675px;
        stroke-dashoffset: 3675px;
        animation: hex-path 1s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards;
    }

    /* exit animations */
    #home.out .hex-svg .hex-path {
        stroke-dashoffset: 0px;
        animation: hex-path-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
    }
    
    #home.out .arrows-cont img {
        translate: 0;
        animation: translate-x--110 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0s forwards;
    }

}

/* exit animations */
#home.out main h1 #big-h1 {
    translate: 0;
    animation: translate-x--110 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

#home.out main h1 #small-h1 {
    translate: 0;
    animation: translate-x--110 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s forwards;
}

#home.out main h1::before {
    clip-path: inset(0 0 0 0);
    animation: clip-path-r 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

#home.out main .left p {
    opacity: 1;
    translate: 0 0;
    animation: translate-y-50 0.5s ease 0s forwards, 
               opacity-0 0.5s ease 0s forwards;
} 

#home.out .hex-svg .hex-path {
    stroke-dashoffset: 0px;
    animation: hex-path-out 1s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

#home.out .arrows-cont img {
    translate: 0;
    animation: translate-x--110 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0s forwards;
}

#home.out main .right img {
    clip-path: inset(0 0 0 0);
    animation: clip-path-t 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

#home.out main .right span,
#home.out > p {
    opacity: 1;
    animation: opacity-0 0.5s ease 0s forwards;
}

#home.out main .border-button {
    opacity: 1;
    animation: opacity-0 0.3s ease 0.3s forwards;
}

#home.out main .right {
    opacity: 1;
    animation: opacity-0 0.3s ease 0.8s forwards;
}

#home.out #home-line-1,
#home.out #home-line-3 {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-t 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards,
               line-bg-out 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards;
}

#home.out #home-line-2 {
    background-color: hsl(var(--bg-fff) / .05);
    clip-path: inset(0 0 0 0);
    animation: clip-path-b 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards,
               line-bg-out 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s forwards;
}