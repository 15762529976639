#main-nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    width: 100%;
    height: var(--navbar-height);

    opacity: 0;
    animation: opacity-1 .5s ease 1s forwards;
}

#main-nav.out {
    opacity: 1;
    animation: opacity-0 .5s ease 0.15s forwards;
}


/* mobile nav */

#main-nav .mob {
    width: 100%;
}

#main-nav .mob .bar {
    width: 100%;
    height: var(--navbar-height);
    background: linear-gradient(hsl(var(--bg-151)), hsl(var(--bg-151) / 0));

    padding-inline: var(--mob-side-padding);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#main-nav .mob .bar .hamburger {
    width: 30px;
    height: 50px;
    position: relative;
    cursor: pointer;
}
#main-nav .mob .bar .hamburger .hamburger-line {
    position: absolute;
    height: 3px;
    width: 25px;
    background-color: hsl(var(--bg-fff));
    pointer-events: none;

    clip-path: inset(0 0 0 0);
}
#main-nav .mob .bar .hamburger .top-line {
    top: 19px;
    left: 0px;
    transition: clip-path 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0.3s;
}
#main-nav .mob .bar .hamburger .bot-line {
    width: 18px;
    bottom: 19px;
    left: 0px;
    transition: clip-path 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0.3s;
}
#main-nav .mob.opened .bar .hamburger .top-line {
    clip-path: inset(0 100% 0 0);
    transition: clip-path 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0s;
}
#main-nav .mob.opened .bar .hamburger .bot-line {
    clip-path: inset(0 0 0 100%);
    transition: clip-path 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0s;
}
#main-nav .mob .bar .hamburger .x-line-1 {
    top: 50%;
    left: 0%;
    translate: 0 -50%;
    rotate: 45deg;
    clip-path: inset(0 100% 0 0);
    transition: 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0s;
}
#main-nav .mob .bar .hamburger .x-line-2 {
    top: 50%;
    left: 0%;
    translate: 0 -50%;
    rotate: -45deg;
    clip-path: inset(0 100% 0 0);
    transition: 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0s;
}
#main-nav .mob.opened .bar .hamburger .x-line-1,
#main-nav .mob.opened .bar .hamburger .x-line-2 {
    clip-path: inset(0 0 0 0);
    transition: 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0.3s;
}

#main-nav .mob .bar .logo img {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    height: 50px;
}

#main-nav .mob .bar .user {
    height: 20px;
}
#main-nav .mob .bar .user img {
    height: 20px;
}

#main-nav .mob.opened {
    width: 100%;
    height: 100%;
}

#main-nav .mob .dropdown {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    
    width: 100%;
    height: auto;
    background-color: hsl(var(--bg-151));

    padding: var(--mob-side-padding);
    padding-top: var(--navbar-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    translate: 0 -100%;
    animation: translate-0 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) 0.2s forwards;
}

#mob-lang-button {
    font-size: 14px;
    margin-top: 1.5rem;
    font-weight: 600;
    color: hsl(var(--bg-accent));
}


/* desktop nav */

#main-nav .dk {
    width: 100%;
    height: var(--navbar-height);
    background: linear-gradient(hsl(var(--bg-151)), hsl(var(--bg-151) / .8), hsl(var(--bg-151) / 0));
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.15em;

    padding-inline: 3rem;
    display: grid;
    grid-template-columns: 1fr var(--container-width) 1fr;
    align-items: center;
}

#main-nav .dk .left img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    translate: 0 10%;
}

#main-nav .dk .center {
    display: flex;
    column-gap: 2rem;
}
#main-nav .dk .center li:last-of-type {
    margin-left: auto;
    color: hsl(var(--fs-accent));
}

#main-nav .dk .right {
    display: flex;
    justify-content: flex-end;
}

#lang-button {
    font-size: 14px;
    font-family: 'Poppins';
}