.lined {
    position: relative;
}

.input-line {
    --line-animation-strength: 0.15;

    width: 100vw;
    height: 3px;
    position: absolute;
    left: 0;
    translate: calc(-1 * var(--mob-side-padding));

    clip-path: inset(0% 0% 0% 100%);
    background-color: hsl(var(--bg-fff) / .3);
    animation: line-in 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s forwards;
}

.out .input-line {
    clip-path: inset(0% 0% 0% 0%);
    background-color: hsl(var(--bg-fff) / .05);
    animation: clip-path-r 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}

.lined .input-line + .input-line {
    clip-path: inset(0% 100% 0% 0%);
    background-color: hsl(var(--bg-fff) / .15);
    animation: line-in 0.7s ease 0.4s forwards;
}

.out .lined .input-line + .input-line {
    clip-path: inset(0% 0% 0% 0%);
    background-color: hsl(var(--bg-fff) / .05);
    animation: clip-path-l 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s forwards;
}


@media screen and (min-width: 750px) {
    .input-line {
        translate: calc(-1px * var(--left));
    }

    .lined.mob .input-line {
        display: none;
    }
}

.input-topline {
    top: 0;
}

.input-botline {
    bottom: 0;
}
