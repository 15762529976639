.alert-modal {
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: hsl(var(--bg-111) / 0.5 );
}

.alert-modal .modal {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);

    width: max-content;
    max-width: 80%;
    background-color: hsl(var(--bg-111));
    border: 3px solid hsl(var(--bg-accent));
    text-align: center;

    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    translate: 0 calc(-100% - 60px);
    animation: translate-0 0.3s cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
}

.alert-modal .modal p {
    color: hsl(var(--fs-fff) / .8 );
}

.alert-modal .buttons {
    display: flex;
    column-gap: .5rem;
}

.alert-modal button {
    margin-top: 1rem;

    background-color: hsl(var(--bg-accent));
    font-size: 12px;
    padding: 0.3em 1em;
}
.alert-modal button.light {
    background-color: hsl(var(--fs-fff) / .15 );
}

@media screen and (min-width: 1100px) {
    .alert-modal .modal {
        max-width: 70%;
        padding: 2rem;
    }
}